<template>
  <div>
    <BaseCard
      :actions-visible="isSuperAdmin"
      :resource="$data.$constants.RESOURCES.RESOURCE_CATEGORIES"
      :title="category? category.name : '' "
      :footer-visible="false"
      :loading="loading"
      @edit-icon-click="$router.push({ name: 'editCategory', params: { id: $route.params.id} })"
      @delete-confirmation="handleDeleteConfirmation"
    >
      <ProfilePanel :profile="categoryProfile" />
    </BaseCard>
    <CategoryAttributesList :category-id="categoryId" v-if="isSuperAdmin"/>
  </div>
</template>

<script>
import CategoriesApi from '@/api/categories-api'
import CategoryAttributesList from '@/components/category/list/CategoryAttributesList.vue'
import { mapActions, mapGetters } from 'vuex'
import ProfilePanel from '@/components/ui/panel/ProfilePanel.vue'
import BaseCard from '@/components/ui/card/BaseCard.vue'

export default {
  components: { BaseCard, ProfilePanel, CategoryAttributesList },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapGetters('category', {
      category: 'getCategory',
    }),
    categoryId() {
      return parseInt(this.$route.params?.id, 10) || null
    },
    categoryProfile() {
      if (!this.category) {
        return []
      }

      return [
        { label: 'Grupo de categorías', value: this.category?.category_group?.name || 'Sin grupo de categorías' },
        { label: 'Categoría padre', value: this.category?.parent?.name || 'Sin categoría padre' },
        { label: 'Descripción', value: this.category?.description || 'Sin descripción' },
        { label: 'Referencia', value: this.category?.reference || 'Sin referencia' },
      ]
    },
    isSuperAdmin() {
      return JSON.parse(localStorage.getItem('userData')).roles[0].name === 'super_admin'
    },
  },
  mounted() {
    this.loadCategoryDetails()
  },
  methods: {
    ...mapActions('category', ['loadCategory']),
    ...mapActions('app', ['setLoading']),
    async loadCategoryDetails() {
      this.loading = true
      try {
        await this.loadCategory(this.$route.params.id)
      } catch (error) {
        console.error(error)
        this.$toast.error('Ocurrió un error al cargar la categoría. Por favor, inténtelo de nuevo mas tarde.')
      }
      this.loading = false
    },
    async handleDeleteConfirmation() {
      this.setLoading(true)
      try {
        await CategoriesApi.delete(this.category.id)
        await this.$router.push({ name: 'categories' })
      } finally {
        this.setLoading(false)
      }
    },
  },
}
</script>

<style scoped>
</style>
